import { Button, type ButtonProps, Icon, Stack, Text } from '@carvertical/ui';
import { type ChangeEvent, useId, useState, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { replaceForeignChars } from 'utils/vin';
import { FitText } from 'components/common/FitText';
import { IdentifierErrorMessage } from './IdentifierErrorMessage';
import { VinExplanation } from './VinExplanation';
import type { SurroundingBackground } from './types';
import type { IdentifierFormFields } from './schemas';
import styles from './IdentifierInput.module.scss';

type IdentifierInputProps = {
  id?: string;
  label: string;
  activePlaceholder?: string;
  labelHidden?: boolean;
  size?: 'l' | 'm';
  loading?: boolean;
  error?: string;
  prefix?: React.ReactNode;
  explanationShown?: boolean;
  outsideButtonShown?: boolean;
  surroundingBackground?: SurroundingBackground;
  buttonVariant?: ButtonProps['variant'];
  buttonLabel?: string;
  status?: string;
  titleShown?: boolean;
};

const VIN_PLACEHOLDER = '1VWBN7A35CCXXXXXX';

const PREFIX_WIDTH = 56;

const PREFIX_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, width: 0 },
  visible: { opacity: 1, width: PREFIX_WIDTH },
};

const EXPLANATION_BUTTON_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, width: 0 },
  visible: { opacity: 1, width: 'auto' },
};

const PLACEHOLDER_ANIMATION_VARIANTS = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const PLACEHOLDER_TRANSITION_CONFIG = {
  duration: 0.1,
};

const IdentifierInput = forwardRef<HTMLInputElement, IdentifierInputProps>(
  (
    {
      activePlaceholder = VIN_PLACEHOLDER,
      buttonLabel = undefined,
      buttonVariant,
      error,
      explanationShown = true,
      id,
      label,
      loading = false,
      labelHidden = true,
      outsideButtonShown = false,
      prefix,
      size = 'l',
      surroundingBackground = 'mediumDark',
      status: _,
      ...props
    }: IdentifierInputProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const errorMessageId = useId();
    const { setValue, watch, register } = useFormContext<IdentifierFormFields>();
    const [inputFocused, setInputFocused] = useState(false);
    const [vinExplanationShown, setVinExplanationShown] = useState(false);

    const mediumSized = size === 'm';
    const placeholder = !labelHidden || inputFocused ? activePlaceholder : label;
    const value = watch('identifier');

    const commonButtonProps = {
      variant: buttonVariant,
      disabled: loading,
      type: 'submit',
    } as const;

    const getButtonText = () => {
      if (loading) {
        return t('vinForm.pendingMessage');
      }

      return buttonLabel || t('vinForm.action');
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = event.target;

      setValue('identifier', replaceForeignChars(inputValue));
    };

    return (
      <>
        <Text as="label" variant="s" className={cx(labelHidden && 'sr-only')}>
          {t('vinForm.placeholder')}
        </Text>

        <Stack
          type={{
            mobileUp: mediumSized ? 'horizontal' : 'vertical',
            tabletPortraitUp: 'horizontal',
          }}
          className={cx(
            styles.root,
            styles[size],
            loading && styles.disabled,
            inputFocused && styles.focused,
            error && styles.error,
            surroundingBackground === 'light' && styles.light,
          )}
          gap={{ mobileUp: mediumSized ? 1 : 2, tabletPortraitUp: 1 }}
          crossAxisAlign="stretch"
        >
          <div className={styles.stretchedItem}>
            <div className={styles.container}>
              <Stack
                gap={0}
                type="horizontal"
                className={styles.roundedWrapper}
                crossAxisAlign="center"
              >
                <AnimatePresence initial={false}>
                  {prefix && (
                    <motion.div
                      variants={PREFIX_ANIMATION_VARIANTS}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      className={cx(styles.prefix, styles.roundedWrapper)}
                    >
                      {prefix}
                    </motion.div>
                  )}
                </AnimatePresence>

                <label className={styles.labelWrapper}>
                  <span className={styles.label}>{label}</span>

                  <input
                    {...register('identifier', {
                      onBlur: () => setInputFocused(false),
                    })}
                    id={id}
                    ref={ref}
                    disabled={loading}
                    className={cx(styles.input, error && styles.error)}
                    onInput={handleInputChange}
                    aria-invalid={!!error}
                    aria-describedby={errorMessageId}
                    onFocus={() => setInputFocused(true)}
                    {...props}
                  />

                  <AnimatePresence mode="wait" initial={false}>
                    {!value && (
                      <motion.span
                        key={placeholder}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        aria-hidden
                        variants={PLACEHOLDER_ANIMATION_VARIANTS}
                        className={styles.placeholder}
                        transition={PLACEHOLDER_TRANSITION_CONFIG}
                      >
                        <FitText minSize={12} maxSize={20}>
                          {placeholder}
                        </FitText>
                      </motion.span>
                    )}
                  </AnimatePresence>
                </label>

                <AnimatePresence>
                  {explanationShown && (
                    <motion.button
                      type="button"
                      variants={EXPLANATION_BUTTON_ANIMATION_VARIANTS}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      disabled={loading}
                      onClick={() => setVinExplanationShown(true)}
                      className={styles.explainerButton}
                      aria-label={t('vin.whatQuestion')}
                    >
                      <Icon name="question-mark-round" size="s" />
                    </motion.button>
                  )}
                </AnimatePresence>
              </Stack>
            </div>

            {!mediumSized && (
              <IdentifierErrorMessage className={styles.mobileError} message={error} />
            )}
          </div>

          {!outsideButtonShown && (
            <div className={styles.buttonWrapper}>
              {mediumSized ? (
                <Button
                  {...commonButtonProps}
                  aria-label={getButtonText()}
                  className={styles.roundButton}
                >
                  <Icon name="arrow-right" size="s" />
                </Button>
              ) : (
                <Button {...commonButtonProps} size="l" className={styles.submitButton}>
                  {getButtonText()}
                </Button>
              )}
            </div>
          )}
        </Stack>

        <IdentifierErrorMessage
          className={cx(!mediumSized && styles.desktopError)}
          message={error}
        />

        {error && (
          <p id={errorMessageId} className="visuallyHidden">
            {error}
          </p>
        )}

        {outsideButtonShown && (
          <Button
            {...commonButtonProps}
            size="l"
            className={cx('w-full md:w-fit', error ? 'mt-2' : 'mt-3')}
          >
            {getButtonText()}
          </Button>
        )}

        <VinExplanation open={vinExplanationShown} onClose={() => setVinExplanationShown(false)} />
      </>
    );
  },
);

export { IdentifierInput };
export type { IdentifierInputProps };
